/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/api';
import $ from 'jquery';
import toastr from 'toastr';
import updateMenuCart from './update-menu-cart';
import getCart from './get-cart';

// Toastr
// https://github.com/CodeSeven/toastr
toastr.options.progressBar = true;

const validationElement = $('#sylius-cart-validation-error');
const $cartCount = $('#cart-count');

$.fn.extend({
  addToCart() {
    return this.each(function () {
      const $element = $(this);
      const url = $element.attr('action');
      const productName = $element.data('product-name');
      const redirectUrl = $element.data('redirect');
      const variant = $('[name="sylius_add_to_cart[cartItem][variant]"]').children('option:selected');
      $element.api({
        method: 'POST',
        on: 'submit',
        cache: false,
        url,
        beforeSend(settings) {
          /* eslint-disable-next-line no-param-reassign */
          settings.data = $element.serialize();

          return settings;
        },
        onSuccess() {
          validationElement.addClass('hidden');
          // Do not redirect
          // window.location.href = redirectUrl;
          // $cartCount.html((parseInt($cartCount.html(),10) || 0) + 1);
          getCart().then((cart) => {
            updateMenuCart(cart);
            toastr.success(
              '<p class="mb-s">' + productName + ' was successfully added to cart!</p>'
              + '<a href="' + redirectUrl + '" class="button outline">View Cart</a>'
              , 'Added to cart 👍');
          });
        },
        onFailure(response) {
          validationElement.removeClass('hidden');
          let validationMessage = '';

          var variant = $(this).find('[id="sylius_add_to_cart[cartItem][simple]"]');
          if (!variant.data('variant-on-hand')) {
            variant = $(this).find('[name="sylius_add_to_cart[cartItem][variant]"]').children('option:selected');
          }
          const quantityValue = parseInt($(this).find('[name="sylius_add_to_cart[cartItem][quantity]"]').val());
          const onHand = parseInt(variant.data('variant-on-hand'));
          const isAvailable = variant.data('variant-available');
          const variantCode = variant.data('variant-code');
          let realTimeOnHand = onHand;

          getCart().then((cart) => {
            let cartItems = cart.items;
            cartItems.forEach( function myFunction(item) {
              if (item.code === variantCode) {
                realTimeOnHand -= item.quantity;
              }
            });
            if (isAvailable === 1 && quantityValue > realTimeOnHand && realTimeOnHand != 0) {
              toastr.error(`There isn't enough stock available for that many items. You can add a maximum of ${realTimeOnHand} items`, 'Cart');
            } else {
              toastr.error('This product could not be added to cart!', 'Cart');
            }
          });

          Object.entries(response.errors.errors).forEach(([, message]) => {
            validationMessage += message;
          });
          validationElement.html(validationMessage);
          $element.removeClass('loading');
        },
      });
    });
  },
});
