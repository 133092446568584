import $ from 'jquery';

$(document).ready(() => {
  $('.reset-filters').on('click', function() {
    $('#filter-section input[type="text"]').val('');
    $('#filter-section input[type="checkbox"]').prop('checked', false);
    $('input:radio[name=category]').each(function () { $(this).prop('checked', false); });
    $('input:radio[name=brand]').each(function () { $(this).prop('checked', false); });
    $('input:radio[name=collection]').each(function () { $(this).prop('checked', false); });
    $('input:radio[name=sale_special]').each(function () { $(this).prop('checked', false); });
  });
});

