/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import $ from 'jquery';

const handleProductOptionsChange = function handleProductOptionsChange() {
  $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', (e) => {
    const $select = $(e.currentTarget);
    const option = $select.data('option');
    const selected = $select.find('option:selected').val();
    const selector = `[data-${option}="${selected}"]`;
    const price = $select.closest('form')
                    .find('.sylius-variants-pricing')
                    .find(selector)
                    .data('value');
    const $price = $select.closest('.js-product').find('.product-price');
    if (price !== undefined) {
      $price.text(price);
      $('button[type=submit]').removeAttr('disabled');
    } else {
      $price.text($('.sylius-variants-pricing').attr('data-unavailable-text'));
      $('button[type=submit]').attr('disabled', 'disabled');
    }
  });
};

const handleProductVariantsChange = function handleProductVariantsChange() {
  $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (e) => {
    const $select = $(e.currentTarget);
    const price = $select.find('option:selected').data('variant-price');
    const originalPrice = $select.find('option:selected').data('variant-original-price');
    const msrp = $select.find('option:selected').data('variant-msrp');
    const onHand = parseInt($select.find('option:selected').data('variant-on-hand'));
    $select.closest('.js-product').find('.product-price .js-swap').text(price);
    $select.closest('.js-product').find('.product-price .sale').text(originalPrice);
    $select.closest('.js-product').find('.product-msrp .js-swap').text(msrp);
    if (onHand === 0 || onHand > 5) {
      $('.inventory-message').addClass('hidden');
    } else {
      $('.inventory-message').removeClass('hidden');
    }

  });
};

$.fn.extend({
  variantPrices() {
    handleProductVariantsChange();
  },
});
