import $ from 'jquery';

$('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (e) => {
  var variant = $(e.currentTarget).children('option:selected');
  var productSpace = $('.product-single');
  const onHand = (variant.data('variant-available'));
  if (onHand !== 1) {
    productSpace.find('.add-item-to-cart-if-available').addClass('hidden');
    productSpace.find('.back-in-stock-feature').removeClass('hidden');
    productSpace.find('.back-in-stock-button').removeClass('hidden');
    productSpace.find('.back-in-stock-message').remove();
  } else {
    productSpace.find('.back-in-stock-feature').addClass('hidden');
    productSpace.find('.add-item-to-cart-if-available').removeClass('hidden');
  }
});

$('.back-in-stock-button').on('click', (e) => {
  var productSpace = $('.product-single');
  var variant = productSpace.find('[name="sylius_add_to_cart[cartItem][variant]"]').children('option:selected');
  if (variant.length === 0) {
    variant = productSpace.find('[id="sylius_add_to_cart[cartItem][simple]"]');
  }
  var variantCode = variant.data('variant-code');
  var url = $(e.currentTarget).attr('action');
  $.ajax({
    url: url,
    type: "POST",
    dataType: "json",
    data: {
      "variantCode": variantCode
    },
    async: true,
    success: function (data)
    {
      productSpace.find('.back-in-stock-button').after('<p class="back-in-stock-message">' + data.message + '</p>');
      productSpace.find('.back-in-stock-button').addClass('hidden');
    }
  });
});

$(document).ready(() => {
  $('.product-single').find('[name="sylius_add_to_cart[cartItem][variant]"] option').each(function(){
    $(this).prop('disabled', false);
  });
});
