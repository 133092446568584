/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/api';
import $ from 'jquery';
import toastr from 'toastr';
import updateMenuCart from './update-menu-cart';
import getCart from './get-cart';
import formatMoney from './format-money';


// Toastr
// https://github.com/CodeSeven/toastr
toastr.options.progressBar = true;

$.fn.extend({
  removeFromCart() {
    const $shippingTotal = $('#sylius-cart-shipping-total');
    const $taxTotal = $('#sylius-cart-tax-total');
    const $total = $('#sylius-cart-grand-total');
    this.each((index, element) => {
      const redirectUrl = $(element).data('redirect'),
            csrfToken = $(element).data('csrf-token');

      $(element).api({
        method: 'DELETE',
        on: 'click',
        beforeSend(settings) {
          /* eslint-disable-next-line no-param-reassign */
          settings.data = {
            _csrf_token: csrfToken,
          };

          return settings;
        },
        onSuccess() {
          // Do not redirect
          // window.location.replace(redirectUrl);
          getCart().then((cart) => {
            $(element).closest('article').remove();
            updateMenuCart(cart);
            $shippingTotal.text(formatMoney(cart.shippingTotal));
            $taxTotal.text(formatMoney(cart.taxTotal));
            $total.text(formatMoney(cart.total));
            $("article.cart-product input").each(function(i) {
              $(this).attr('name', 'sylius_cart[items][' + i + '][quantity]')
            });
            toastr.success(
              '<p class="mb-s">' + 'Product was successfully removed from cart!</p>'
              , 'Cart Updated');
          });
        },
        onFailure(response) {
          toastr.error('This product could not be removed from cart!', 'Cart');
        }
      });
    });
  },
});
