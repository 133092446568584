/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/dropdown';
import $ from 'jquery';

const provinceInput = $('#sylius_checkout_address_shippingAddress_province');
const provinceInputBilling = $('#sylius_checkout_address_billingAddress_province');

const fixProvinceField = () => {
  let currentValue = provinceInput.val();
  currentValue = currentValue.replace(/_/g, ' ');
  provinceInput.val(currentValue);

  currentValue = provinceInputBilling.val();
  currentValue = currentValue.replace(/_/g, ' ');
  provinceInputBilling.val(currentValue);
};

if (provinceInput.length) {
  fixProvinceField();
}

const parseKey = (key) =>  {
  return key.replace(/(_\w)/g, words => words[1].toUpperCase());
};

$.fn.extend({
  addressBook() {
    const element = this;
    const select = element.find('.address-book-select');
    const findByName = function findByName(name) {
      return element.find(`[name*="[${parseKey(name)}]"]`);
    };

    select.dropdown({
      forceSelection: false,

      onChange(name, text, choice) {
        element.find('input:not([type="radio"]):not([type="checkbox"]), select').each((index, input) => {
          $(input).val('');
        });

        Object.entries(choice.data()).forEach(([property, value]) => {
          const field = findByName(property);

          if (property.indexOf('countryCode') !== -1) {
            field.val(value).change();
            fixProvinceField();
          } else if (field.is('[type="radio"]') || field.is('[type="checkbox"]')) {
            field.prop('checked', false);
            field.filter(`[value="${value}"]`).prop('checked', true);
          } else {
            field.val(value);
          }
        });
      },
    });
  },
});
